import RegistrationFormService from './RegistrationFormService';

const PAGE_SIZE = 20;
const DATE_FORMAT = "DD.MM.YYYY HH:mm:ss.000";

export function searchRegistrationForms(servicePartnerId, searchFilter) {
    const searchTerm = searchFilter.get('term');
    const currentPage = searchFilter.get('currentPage');
    const offset = PAGE_SIZE * (currentPage - 1);
    const sortField = searchFilter.getIn(['sorting', 'field']);
    const sortDirection = searchFilter.getIn(['sorting', 'direction']);

    const startDate = makeDateStr(searchFilter.getIn(['dateRange', 'start']));
    const endDate = makeDateStr(searchFilter.getIn(['dateRange', 'end']));

    const timeframe = searchFilter.get('timeFrame');
    const checkedinOnly = searchFilter.get('checkedinOnly');

    return RegistrationFormService.getRegistrationForms(servicePartnerId, PAGE_SIZE, offset, sortField, sortDirection, searchTerm, timeframe, checkedinOnly, startDate, endDate);
}

export function searchRegistrationFormsGroup(servicePartnerGroupId, searchFilter) {
    const searchTerm = searchFilter.get('term');
    const currentPage = searchFilter.get('currentPage');
    const offset = PAGE_SIZE * (currentPage - 1);
    const sortField = searchFilter.getIn(['sorting', 'field']);
    const sortDirection = searchFilter.getIn(['sorting', 'direction']);

    const startDate = makeDateStr(searchFilter.getIn(['dateRange', 'start']));
    const endDate = makeDateStr(searchFilter.getIn(['dateRange', 'end']));

    const timeframe = searchFilter.get('timeFrame');
    const checkedinOnly = searchFilter.get('checkedinOnly');

    return RegistrationFormService.getRegistrationFormsGroup(servicePartnerGroupId, PAGE_SIZE, offset, sortField, sortDirection, searchTerm, timeframe, checkedinOnly, startDate, endDate);
}

export function getGuestCountsPerDay(servicePartnerId, searchFilter) {
    const startDate = makeDateStr(searchFilter.getIn(['dateRange', 'start']));
    const endDate = makeDateStr(searchFilter.getIn(['dateRange', 'end']));
    const searchTerm = searchFilter.get('term');
    const timeframe = searchFilter.get('timeFrame');
    const checkedinOnly = searchFilter.get('checkedinOnly');

    return RegistrationFormService.getRegistrationCountsPerDay(servicePartnerId, startDate, endDate, searchTerm, checkedinOnly, timeframe);
}

function makeDateStr(moment) {
    return moment ? moment.format(DATE_FORMAT) : '';
}

export default {
    searchRegistrationForms,
    getGuestCountsPerDay,
    searchRegistrationFormsGroup
}

