import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {SelfServiceMainLayout} from "./layout/mainLayout";
import {SelfServiceStepLayout} from "./layout/stepLayout";
import {Actions} from "./redux";

export class SelfServiceStep12Component extends React.Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        clearReduxStateSelfService: PropTypes.func
    };

    componentDidMount(){
        if(this.props.clearReduxStateSelfService){
            this.props.clearReduxStateSelfService();
        }
    }

    render() {
        const id = this.props.match.params.id;
        return (
            <SelfServiceMainLayout title={{id: 'bonfire.containers.SelfServiceStep1Page.title', defaultMessage: 'Pre-Check-In'}} class="step10">

                <SelfServiceStepLayout id={id} backActivated={false} closeActivated={false} title={{id: 'bonfire.containers.SelfServiceStep12Page.stepTitle', defaultMessage: 'Pre-Check-in'}}/>


                <div className="text">
                    <div className="wrapper">

                        <p className="card-content-1 sar-big">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep10Page.card.content1'
                                defaultMessage='Der Pre-Check-in wurde erfolgreich abgeschlossen.'
                            />
                        </p>

                        <p className="card-content-2 sar-big">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep10Page.card.content2'
                                defaultMessage="Besuchen Sie für weitere Angebote und Informationen www.zermatt.swiss."
                                values={{
                                    a(fragments) {return <a href="https://www.zermatt.swiss" target="_blank" rel="noopener">{fragments}</a>}
                                }}
                            />
                        </p>

                        <p className="card-content-3 sab-big">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep10Page.card.content3'
                                defaultMessage='Wir wünschen ihnen einen schönen Aufenthalt!'
                            />
                        </p>

                    </div>
                </div>
                <div className="bottom"></div>


            </SelfServiceMainLayout>
        );
    }
}

export const SelfServiceStep12 = connect(
    state => ({
    }),

    (dispatch) => ({
        clearReduxStateSelfService: () => dispatch(Actions.clearReduxStateSelfService())
    })
)(SelfServiceStep12Component);

