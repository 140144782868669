import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import SignaturePad from "react-signature-pad-wrapper";
import {SelfServiceMainLayout} from "./layout/mainLayout";
import {SelfServiceStepLayout} from "./layout/stepLayout";
import {Actions} from "./redux";


export class SelfServiceStep11Component extends React.Component {

    static propTypes = {
        registrationForm: PropTypes.object.isRequired,
        customerData: PropTypes.any,
        setSignatureData: PropTypes.func.isRequired,
        signatureData: PropTypes.any,
        setCustomerData: PropTypes.func.isRequired,
        fellowTraveler: PropTypes.any,
        updateGuest: PropTypes.func.isRequired,
        updateRegistrationForm: PropTypes.func.isRequired,
        language: PropTypes.string.isRequired,
        comment: PropTypes.string,
        newsletter: PropTypes.any
    };

    constructor(props) {
        super(props);
        this.state = {
            checkedDatasecurity: false,
            signatured: true,
            signature: [],
            showDialog: false
        };

        this.disabled = true;
        this.saveInProgress = false;
        this.modalButton = (
            <button type="button" className="btn btn-primary" id={'self-service-error-modal-toggle'} data-toggle="modal" data-target="#self-service-error-modal" style={{visibility: 'hidden', width: '0', height: '0', margin: '0', paddiing: '0', border: 'none'}}/>
        );

        this.canvasClass = ["canvas-signature"];
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkedChange = this.checkedChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.doTransactions = this.doTransactions.bind(this);
        this.handleSignature = this.handleSignature.bind(this);
    }

    componentDidMount() {
        if(this.props.registrationForm) {
            let primaryGuest = this.props.customerData ? this.props.customerData : this.props.registrationForm.guests.find(guest => guest.primary === true);

            this.setState({
                checkedDatasecurity: this.props.customerData ? primaryGuest.dataProcessingOk : primaryGuest.guest.dataProcessingOk,
                signature: this.props.signatureData ? this.props.signatureData : [],
                signatured: !!this.props.signatureData
            });
        }
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (this.state.showDialog){
            document.getElementById('self-service-error-modal-toggle').click();
        }
    }

    handleSignature(){
        this.setState({
            signatured: !this.signaturePad.isEmpty(),
            signature: this.signaturePad.toData()
        });
    }

    checkedChange(event){
        this.setState({
            checkedDatasecurity: event.target.checked
        });
    }

    doTransactions = async (transactionData) => {
        try {
            await (async () => {
                for (let i = 0; i < transactionData.length; i++) {
                    try {
                        await this.props.updateGuest(transactionData[i].id, transactionData[i].data);
                    } catch (e) {
                        this.setState({
                            showDialog: true
                        });
                        console.log('catch', e)
                    }
                }
            })();
        }
        catch(e) {
            this.setState({
                showDialog: true
            });
            console.log('error updating guest', e);
        }
        Promise.resolve();
    }

    handleSave(customerData) {
        const primaryGuestTransactionData = [{id: this.props.registrationForm.id, data: customerData}];
        const fellowGuestsTransactionData = this.props.fellowTraveler ?
            R.pipe(
                R.toPairs,
                R.map(([, traveler]) => ({id: this.props.registrationForm.id, data: traveler}))
            )(this.props.fellowTraveler) :
            [];
        const transactionData = primaryGuestTransactionData.concat(fellowGuestsTransactionData);

        console.log(transactionData)
        this.doTransactions(transactionData)
            .then(() => {
                this.props.history.push(`/self-service/${this.props.registrationForm.id}/step12`);
            });
    }

    handleSubmit() {
        console.log(this.saveInProgress)
        if(this.saveInProgress){
            return;
        }

        this.saveInProgress = true;
        if(this.state.checkedDatasecurity && this.state.signatured && this.state.signature) {
            this.props.setSignatureData(this.state.signature);

            let customerBefore = this.props.customerData ? this.props.customerData : this.props.registrationForm.guests.find(guest => guest.primary === true);
            const customer = Object.assign({}, this.props.customerData ? customerBefore : customerBefore.guest);

            if (customerBefore) {
                if(customerBefore?.interests?.length > 0 ) {
                    let interestsArray = {};
                    customerBefore?.interests?.forEach((item, key) => {
                        interestsArray[item.value] = item.label;
                    });
                    customer.interests = interestsArray;
                }
                else {
                    customer.interests = []
                }
            }


            customer.guestId = customerBefore.id;
            customer.primary = customerBefore.primary;
            customer.dataProcessingOk = this.state.checkedDatasecurity;
            customer.comment = this.props.comment ? this.props.comment : '';
            customer.newsletter = this.props.newsletter ? this.props.newsletter.zermatt : false;
            customer.newsletterHousing = this.props.newsletter ? this.props.newsletter.housing : false;
            customer.newsletterEmail = this.props.newsletter && this.props.newsletter.email ? this.props.newsletter.email : '';

            let dataReturned = this.props.setCustomerData(customer);

            this.handleSave(dataReturned.customerData);
        }
    }

    render() {
        const id = this.props.registrationForm.id;

        this.disabled = !(this.state.signatured && this.state.checkedDatasecurity);

        const url = `https://www.zermatt.swiss/Media/AGB-Datenschutz `;

        return (
            <SelfServiceMainLayout title={{id: 'bonfire.containers.SelfServiceStep11Page.title', defaultMessage: 'Pre-Check-In'}} class="step9-1" showModal={this.state.showDialog} onCloseModal={() => {
                this.setState({showDialog: false});
            }}>

                <SelfServiceStepLayout id={id} title={{id: 'bonfire.containers.SelfServiceStep11Page.stepTitle', defaultMessage: 'Datenschutz'}}/>

                {this.modalButton}

                <div className="text">

                    <div className="wrapper self-service">

                        <div className="confirm-text sar-big">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep9_1Page.textConfirmLong'
                                defaultMessage='Hiermit bestätige ich, dass alle Angaben vollständig und wahrheitsgetreu angegeben wurden.'
                            />
                        </div>

                        <div className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep9_1Page.signature'
                                defaultMessage='Unterschrift'
                            />
                        </div>

                        <div className={this.canvasClass.join(' ')}>
                            <div className="line"/>
                            <SignaturePad ref={ref => this.signaturePad = ref} options={{minWidth: 2, maxWidth: 2, penColor: '#524440', onEnd: this.handleSignature}}/>
                        </div>

                        <div className="checkbox with-bold">
                            <label className="checkbox-inline checkbox-bootstrap checkbox-lg">
                                <input type="checkbox"
                                       defaultChecked={this.state.checkedDatasecurity}
                                       onChange={this.checkedChange}
                                />
                                <span className="checkbox-placeholder"/>

                                <FormattedMessage
                                    id='bonfire.containers.SelfServiceStep9_1Page.dataSecureLabel'
                                    defaultMessage='Ich habe die Datenschutzbestimmungen von Zermatt Tourismus gelesen und stimme der Weiterverarbeitung der Daten zu.'
                                    values={{
                                        a(fragments) {
                                            return (
                                                <>
                                                    <a href={url} target="_blank" rel="noreferrer">{fragments}</a>
                                                </>)
                                        },
                                        b(fragments) {return <b>{fragments}</b>}
                                    }}
                                />

                            </label>
                        </div>

                    </div>

                </div>
                <div className="bottom">
                    <div onClick={this.handleSubmit}>
                        <button className="b-button-pre-checkin" disabled={this.disabled} id="self-service-step11-confirm-next-button">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep9_1Page.buttonfinish'
                                defaultMessage='Absenden'
                            />
                        </button>
                    </div>
                </div>


            </SelfServiceMainLayout>
        );
    }
}

export const SelfServiceStep11 = connect(
    state => ({
        registrationForm: state.getIn(['selfService', 'registrationForm']),
        customerData: state.getIn(['selfService', 'data', 'customerData']),
        signatureData: state.getIn(['selfService', 'data', 'signature']),
        fellowTraveler: state.getIn(['selfService', 'data', 'fellowTraveler']),
        comment: state.getIn(['selfService', 'data', 'comment']),
        language: state.get('language')['currentLanguage'],
        newsletter: state.getIn(['selfService', 'data', 'newsletter'])
    }),
    (dispatch) => ({
        setSignatureData: signatureData => dispatch(Actions.setSignatureData(signatureData)),
        setCustomerData: customerData => dispatch(Actions.setCustomerData(customerData)),
        updateGuest: (id, guest) => dispatch(Actions.updateGuest(id, guest)),
        updateRegistrationForm: (id, registrationForm) => dispatch(Actions.updateRegistrationForm(id, registrationForm))
    })
)(SelfServiceStep11Component);

