import FilteredRegistrationFormService from 'services/registrationforms/FilteredRegistrationSearchService';
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";
import {RootState} from "App";
import {RegistrationForm} from "bonfire/ZTerminal/RegistrationForms/RegistrationFormModel";

export interface RegistrationFormGuest {

}


export interface RegistrationFormListSliceState {
    loading: boolean
    error: string | false
    forms: RegistrationForm[]
    valid: boolean
    totalCount: number
    pages: number
    highlightedRegistrationFormId: string | null
}

const initialState: RegistrationFormListSliceState ={
    loading: false,
    error: false,
    forms: [],
    valid: false,
    totalCount: 0,
    pages: 0,
    highlightedRegistrationFormId: null
};

const PAGE_SIZE = 20; // TODO WHERE TO GET THIS FROM?
const fetchListIfNecessary = createAsyncThunk(
    'zterminal/registrationForms/list/fetchListIfNecessary',
    (_: void, thunkAPI) => {
        const state = thunkAPI.getState();
        const servicePartner = SessionSelectors.currentServicePartner(state);
        // @ts-ignore
        const searchFilter = state.getIn(['zterminal', 'registrationForms', 'searchFilter']);
        const valid = searchFilter.get('valid');
        if (valid) {
            return Promise.resolve();
        }
        if (servicePartner?.partnerGroup) {
            if (!servicePartner.partnerGroup.individualRegistrationFormOverview) {
                return FilteredRegistrationFormService
                    .searchRegistrationFormsGroup(servicePartner?.partnerGroup.id, searchFilter)
                    .catch((error: any) => thunkAPI.rejectWithValue(error.toString()));
            } else {
                return FilteredRegistrationFormService
                    .searchRegistrationForms(servicePartner?.id, searchFilter)
                    .catch((error: any) => thunkAPI.rejectWithValue(error.toString()));
            }
        } else {
            return FilteredRegistrationFormService
                .searchRegistrationForms(servicePartner?.id, searchFilter)
                .catch((error: any) => thunkAPI.rejectWithValue(error.toString()));
        }
    }
);

const {actions, reducer} = createSlice({
    name: 'zterminal/registrationForms/list',
    initialState,
    reducers: {
        setValid: (state, action: PayloadAction<boolean>) => {
            state.valid = action.payload;
        },
        setHighlightedRegistrationFormId: (state, action: PayloadAction<string>) => {
            state.highlightedRegistrationFormId = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchListIfNecessary.pending, state => {
            state.loading = true;
            state.error = false;
            state.forms = [];
        });
        builder.addCase(fetchListIfNecessary.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.toString();
            state.forms = [];
        });
        builder.addCase(fetchListIfNecessary.fulfilled, (state, action) => {
            state.loading = false;
            state.error = false;
            state.forms = action.payload.data;
            state.totalCount = action.payload.totalCount;
            state.pages = Math.ceil(action.payload.totalCount / PAGE_SIZE);
            state.valid = true;
        });
    }
});


export const RegistrationFormListActions = {
    fetchListIfNecessary,
    invalidate: () => actions.setValid(false),
    setHighlightedRegistrationFormId: actions.setHighlightedRegistrationFormId
};

export const registrationFormListReducer = reducer;

function getRegistrationFormListState(state: RootState): RegistrationFormListSliceState {
    return state.getIn(['zterminal', 'registrationForms', 'registrationFormList']);
}

export const RegistrationFormListSelectors = {
    pages: (state: RootState) => getRegistrationFormListState(state).pages,
    loading: (state: RootState) => getRegistrationFormListState(state).loading,
    registrationForms: (state: RootState) => getRegistrationFormListState(state).forms,
    totalCount: (state: RootState) => getRegistrationFormListState(state).totalCount,
    highlightedRegistrationFormId: (state: RootState) => getRegistrationFormListState(state).highlightedRegistrationFormId
}
